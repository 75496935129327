import { Select } from "@mantine/core";
import { useTranslation } from "react-i18next";
import {
  getGetApiOrganizationGetOrganizationsKey,
  useGetApiOrganizationGetOrganizations,
} from "../api/endpoints";
import useSelectedOrganizationId from "../hooks/useSelectedOrganizationId";
import Loading from "./Loading";
import { mutate } from "swr";
import { useEffect } from "react";
import { useConfig } from "../hooks/useConfig";

const SelectOrganization = () => {
  const { t } = useTranslation();
  const {
    data: orgs,
    error,
    isLoading,
  } = useGetApiOrganizationGetOrganizations();
  const config = useConfig();

  const [selectedOrganizationId, setSelectedOrganizationId] =
    useSelectedOrganizationId();

  useEffect(() => {
    if (!selectedOrganizationId && orgs?.length) {
      const firstOrg = Array.isArray(orgs) && orgs.length && orgs[0];
      if (firstOrg) {
        setSelectedOrganizationId(firstOrg.id!);
      }
    }
  }, [selectedOrganizationId, orgs, setSelectedOrganizationId]);

  if (isLoading) {
    return <Loading></Loading>;
  }
  if (!Array.isArray(orgs) || error) {
    mutate(getGetApiOrganizationGetOrganizationsKey());
    return <Loading></Loading>;
  }
  return !!config.organizationSelection ? (
    <Select
      label={t("components.select-current-organization")}
      placeholder={t("components.pick")}
      data={
        orgs?.map((o) => {
          return {
            value: o.id!,
            label: o.name!,
          };
        })!
      }
      value={selectedOrganizationId}
      onChange={setSelectedOrganizationId}
    />
  ) : (
    <></>
  );
};
export default SelectOrganization;
