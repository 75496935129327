import { SurveyCreator, SurveyCreatorComponent } from "survey-creator-react";

import "survey-core/survey.i18n.js";
import "survey-creator-core/survey-creator-core.i18n.js";

import "survey-core/defaultV2.css";
import "survey-creator-core/survey-creator-core.css";
import { t } from "i18next";

const creatorJson = {
  completedHtml:
    "<h3>" +
    t("pages.admin.thank-your-feedback") +
    "</h3><h5>" +
    t("pages.admin.message-thoughts-ideas") +
    "</h5>",
  completedHtmlOnCondition: [
    {
      expression: "{nps_score} > 8",
      html:
        "<h3>" +
        t("pages.admin.thank-your-feedback") +
        "</h3><h5>" +
        t("pages.admin.message-your-ideas-suggestions") +
        "</h5>",
    },
    {
      expression: "{nps_score} < 7",
      html:
        "<h3>" +
        t("pages.admin.thank-your-feedback") +
        "</h3><h5>" +
        t("pages.admin.improve-product-reach-expectation") +
        "</h5><br />",
    },
  ],
  pages: [
    {
      name: t("pages.admin.page"),
      elements: [
        {
          type: "rating",
          name: t("pages.admin.nps-score-name"),
          title: t("pages.admin.survey-nps-score"),
          isRequired: true,
          rateMin: 0,
          rateMax: 10,
          minRateDescription: t("pages.admin.most-unlikely"),
          maxRateDescription: t("pages.admin.most-likely"),
        },
        {
          type: "checkbox",
          name: t("pages.admin.promoter-features-name"),
          visible: false,
          visibleIf: "{nps_score} >= 9",
          title: t("pages.admin.survey-promoter-features"),
          isRequired: true,
          validators: [
            {
              type: "answercount",
              text: t("pages.admin.two-features-maximum"),
              maxCount: 2,
            },
          ],
          choices: [
            t("pages.admin.performance"),
            t("pages.admin.stability"),
            t("pages.admin.user-interface"),
            t("pages.admin.complete-functionality"),
          ],
          showOtherItem: true,
          otherText: t("pages.admin.other-feature"),
          colCount: 2,
        },
        {
          type: "comment",
          name: t("pages.admin.passive-experience-name"),
          visible: false,
          visibleIf: "{nps_score} > 6  and {nps_score} < 9",
          title: t("pages.admin.survey-passive-experience"),
        },
        {
          type: "comment",
          name: t("pages.admin.disappointed-experience"),
          visible: false,
          visibleIf: "{nps_score} notempty",
          title: t("pages.admin.survey-disappointed-experience"),
        },
      ],
    },
  ],
  showQuestionNumbers: "off",
};

const creatorOptions = {};

function CreateSurvey() {
  const creator = new SurveyCreator(creatorOptions);
  creator.JSON = creatorJson;

  return <SurveyCreatorComponent creator={creator} />;
}

export default CreateSurvey;
