import {
  Box,
  Button,
  Group,
  Text,
  TextInput,
  Textarea,
  Title, Stack,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { closeAllModals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import {IconArrowLeft, IconAsteriskSimple, IconDownload} from "@tabler/icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { mutate } from "swr";
import {
  getGetApiConfigGetConfigKey,
  putApiImpactFrameworkAddImpactFrameworkSet,
} from "../../api/endpoints";
import {CreateOutcomeTypeDTO, CreateStakeholderTypeDTO, ImpactFrameworkDTO} from "../../api/model";
import ExcelReader from "./ExcelReader";
import AccordionOutcomes from "../../pages/admin/AccordionOutcomes";
import {useEffect, useState} from "react";

export default function CreateFramework() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const form = useForm<{
    name: string;
    description: string;
    excel_design_your_impact: {
      "Impact framework":any[],
      "Stakeholder":any[],
    };
  }>({
    initialValues: {
      name: "",
      description: "",
      excel_design_your_impact: {
        "Impact framework":[],
        "Stakeholder":[],
      },
    },
  });
  
  const [impactFramework, setImpactFramework]= useState<{
    name:string,
    description:string,
    outcomes:CreateOutcomeTypeDTO[],
    stakeholders:CreateStakeholderTypeDTO[],
  }>({
    name:"",
    description:"",
    outcomes:[],
    stakeholders:[],
  })

  const downloadTemplate = () => {
    const filePath = "ImpactFrameworkTemplate.xlsx";
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = process.env.PUBLIC_URL + filePath;
    a.download = "ImpactFramework.xlsx";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  useEffect(()=>{
    setImpactFramework(loadImpactFramework())
    /*if(!impactFramework.stakeholders?.length ||
        !impactFramework.outcomes?.length){
        showNotification({
            title: t("pages.admin.error"),
            message: t("pages.admin.error-saving"),
        });+++
    }*/
  },[form.values.excel_design_your_impact])
  const loadImpactFramework = ()=>({
        name: form.values.name,
        description: form.values.description,
        outcomes: form.values.excel_design_your_impact["Impact framework"].reduce(
            (a, c) => {
              console.log("a",a)
              console.log("c",c)
              if (c == null) return a;
              if(a.filter((x:any)=>x.name === c.outcome).length){
                return a.map((x:any)=>{
                  if(x.name === c.outcome){
                    const duplicateIndicator = !!x.indicators.filter((i:any)=>i.name === c.indicatore).length
                      const indicators = x.indicators.map((i:any)=>{
                        if(i.name === c.indicatore){
                          return {
                            ...i,
                            proxies: [
                                ...i.proxies,
                              {
                                name: c.proxy,
                                defaultValue: c.valoreproxy,
                                sourceName: c.fonteproxy,
                                sourceUrl: c.fonteproxy,
                              },
                            ],
                          }
                        }
                        return i;
                      })
                    return {
                      ...x,
                      indicators : duplicateIndicator ? indicators :[...x.indicators,
                        {
                        name: c.indicatore,
                        description: c.descrizioneIndicatore,
                        proxies: [
                          {
                            name: c.proxy,
                            defaultValue: c.valoreproxy,
                            sourceName: c.fonteproxy,
                            sourceUrl: c.fonteproxy,
                          },
                        ],
                      }]
                    }
                  }
                  return x
                })
              }
              a.push({
                name: c.outcome,
                description: c.descrizioneOutcome,
                sdg: c.sdgNumero,
                indicators: [
                  {
                    name: c.indicatore,
                    description: c.descrizioneIndicatore,
                    proxies: [
                      {
                        name: c.proxy,
                        defaultValue: c.valoreproxy,
                        sourceName: c.fonteproxy,
                        sourceUrl: c.fonteproxy,
                      },
                    ],
                  },
                ],
              });
              return a;
            },[]),
        stakeholders: form.values.excel_design_your_impact["Stakeholder"].reduce(
            (a, c) => {
              if (c == null) return a;
              if(a.filter((x:any)=>x.name === c.stakeholder).length) {
                return a;
              }
              a.push({
                name: c.stakeholder,
                description: c.descrizioneStakeholder
              });
              return a;
            },[]),
      }
    );

  return (
    <Box mx="auto" w={"75%"}>
      <Button
          variant="subtle"
          style={{ marginLeft: -14 }}
          leftIcon={<IconArrowLeft />}
          onClick={() => navigate(-1)}
      >
        {t("pages.admin.go-back")}
      </Button>
      <form
        onSubmit={()=>putApiImpactFrameworkAddImpactFrameworkSet(loadImpactFramework()).then(
            (res) => {
              if (res) {
                showNotification({
                  title: t("pages.admin.success"),
                  message: t("pages.admin.saved"),
                });
                mutate(getGetApiConfigGetConfigKey());
                closeAllModals();
                navigate("/admin", { state: "impactframe" });
              } else {
                showNotification({
                  title: t("pages.admin.error"),
                  message: t("pages.admin.error-saving"),
                });
                closeAllModals();
              }
            }
        )}
      >
        <Title size={20} pt={20} pb={10}>
          {t("pages.admin.new-framework")}
        </Title>

        <TextInput
          withAsterisk
          pb={20}
          label={t("pages.admin.name-framework")}
          {...form.getInputProps("name")}
        />
        <Textarea
          withAsterisk
          pb={20}
          label={t("pages.admin.framework-description")}
          {...form.getInputProps("description")}
        />
        <Text
          onClick={downloadTemplate}
          size={14}
          pt={10}
          pb={10}
          color="#1C7ED6"
          fw={600}
          w={"300px"}
          style={{ cursor: "pointer" }}
          underline
        >
          {t("pages.admin.download-template")}
          <IconDownload size={18} style={{ marginLeft: 10 }} color="#1C7ED6" />
        </Text>
        
          <Text size={14}>
            Excel
            <IconAsteriskSimple
              color="red"
              size={6}
              style={{ marginTop: -10, marginLeft: 2, marginRight: 2 }}
            />
            :
          </Text>
          <ExcelReader form={form} />

        {form.values.excel_design_your_impact["Impact framework"].length &&
          impactFramework.stakeholders && 
          Array.isArray(impactFramework.stakeholders) ? (<>
            <Text ml={20} my={16}>
              <span style={{fontWeight:"bold"}}>Stakeholder</span> 
            </Text>
            <Stack>
              {impactFramework.stakeholders.map(s => (
                  <Text ml={20} my={16}>
                    {s.name}{s.description && <span> - {s.description}</span>}
                  </Text>
              ))}
            </Stack>
            </>
        ): null
        }
          {form.values.excel_design_your_impact["Impact framework"].length ? (
            <Group>
              {
                (impactFramework.outcomes?.length !== 0) ?
                  <div style={{ width: "100%" }}>
                    <AccordionOutcomes framework={impactFramework} />
                  </div>
                  : impactFramework.stakeholders && impactFramework?.stakeholders?.length === 0
                    ? <Text
                      ta="center"
                      c="red"
                    >
                      {t("components.forms.createFramework.errorStakeholders")}

                    </Text>
                    : impactFramework.outcomes && impactFramework?.outcomes?.map((e) => !e?.indicators?.length)
                      ? <Text
                        ta="center"
                        c="red"
                      >
                        {t("components.forms.createFramework.errorIndicators")}
                      </Text>
                      : impactFramework.outcomes && impactFramework?.outcomes?.length <= 0
                        ? <Text
                          ta="center"
                          c="red"
                        >
                          {t("components.forms.createFramework.errorOutcomes")}
                        </Text>
                        : impactFramework.outcomes && impactFramework?.outcomes?.map((e) => !e?.indicators?.map((e) => !e.proxies?.length))
                          ? <Text
                            ta="center"
                            c="red"
                          >
                            {t("components.forms.createFramework.errorProxies")}
                          </Text>
                          : ""
              }
              
            </Group>
          ):null}
        
        <Group w={"100%"} position="right" mb={30} mt="md">
          <Button
            variant="filled"
            color="blue"
            mt="lg"
            radius="md"
            type="submit"
            disabled={
              form.values.name === "" ||
              form.values.description === "" ||
              !form.values.excel_design_your_impact ||
              !impactFramework.stakeholders?.length ||
              !impactFramework.outcomes?.length
            }
          >
            {t("components.submit")}
          </Button>
          <Button
            variant="filled"
            color="red"
            mt="lg"
            radius="md"
            onClick={() => {
              navigate("/admin", { state: "impactframe" });
            }}
          >
            {t("pages.admin.cancel")}
          </Button>
        </Group>
      </form>
    </Box>
  );
}
