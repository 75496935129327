import {
  Box,
  Button,
  Group,
  Select,
  Switch,
  Text,
  TextInput,
  Textarea,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { closeAllModals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import { IconAsteriskSimple } from "@tabler/icons";
import { useTranslation } from "react-i18next";
import { mutate } from "swr";
import {
  getGetApiConfigGetConfigKey,
  postApiConfigUpsertConfig,
} from "../../api/endpoints";
import { AppConfigDTO } from "../../api/model";
import { useConfig } from "../../hooks/useConfig";
import ImageUploader from "./ImageUploaded";
import { useNavigate } from "react-router-dom";

export default function CustomizeInstallation() {
  const { t } = useTranslation();
  const config = useConfig();
  const navigate = useNavigate();

  const form = useForm<AppConfigDTO>({
    initialValues: {
      name: config.name ?? "",
      description: config.description ?? "",
      message: config.message ?? "",
      logoBase64: config.logoBase64 ?? "",
      listName: config.listName ?? "",
      languageSelection: !!config.languageSelection ?? false,
      organizationSelection: !!config.organizationSelection ?? false,
      reportPowerBiId:
        config.reportPowerBiId ?? "00000000-0000-0000-0000-000000000000",
      workspacePowerBiId:
        config.workspacePowerBiId ?? "00000000-0000-0000-0000-000000000000",
      defaultLanguage: config.defaultLanguage ?? "it",
      domain: config.domain ?? "call.openimpact.it",
      dataConsentText: config.dataConsentText  ?? "Acconsento al trattamento dei dati personali trasmessi ai sensi dell'art. 13 del GDPR. Informativa ex art. 10 legge n.196/2003.",
    },
  });

  return (
    <Box mx="auto">
      <form
        onSubmit={form.onSubmit((values) =>
          postApiConfigUpsertConfig(values).then((res) => {
            if (res) {
              showNotification({
                title: t("pages.admin.success"),
                message: t("pages.admin.config-saved"),
              });
              mutate(getGetApiConfigGetConfigKey());
              navigate("/admin", { state: "customize" });
              closeAllModals();
            } else {
              showNotification({
                title: t("pages.admin.error"),
                message: t("pages.admin.error-saving"),
              });
              closeAllModals();
            }
          })
        )}
      >
        <TextInput
          withAsterisk
          pb={20}
          label={t("components.customize-page.config-name")}
          {...form.getInputProps("name")}
        />

        <TextInput
          withAsterisk
          label={t("components.customize-page.welcome-message")}
          {...form.getInputProps("message")}
        />
        <Group>
          <Text size={14}>
            Logo
            <IconAsteriskSimple
              color="red"
              size={6}
              style={{ marginTop: -10, marginLeft: 2, marginRight: 2 }}
            />
            :
          </Text>
          <ImageUploader form={form} />
        </Group>
        <TextInput
          withAsterisk
          pb={20}
          label={t("components.customize-page.list-name")}
          {...form.getInputProps("listName")}
        />
        <Switch
          labelPosition="left"
          pb={20}
          label={t("components.customize-page.language-selection")}
          defaultChecked={config.languageSelection}
          {...form.getInputProps("languageSelection")}
        />
        <Switch
          labelPosition="left"
          pb={20}
          defaultChecked={config.organizationSelection}
          label={t("components.customize-page.organization-selection")}
          {...form.getInputProps("organizationSelection")}
        />
        <TextInput
          withAsterisk
          pb={20}
          placeholder={"00000000-0000-0000-0000-000000000000"}
          label={"PowerBI Workspace Id"}
          {...form.getInputProps("workspacePowerBiId")}
        />
        <TextInput
          withAsterisk
          pb={20}
          label={"PowerBI Report Id"}
          placeholder={"00000000-0000-0000-0000-000000000000"}
          {...form.getInputProps("reportPowerBiId")}
        />
        <Select
          withAsterisk
          pb={20}
          label={t("components.customize-page.default-language")}
          {...form.getInputProps("defaultLanguage")}
          data={["it", "en"]}
          defaultValue={"en"}
        ></Select>
        <Textarea
          withAsterisk
          styles={{ input: { height: 150 } }}
          pb={20}
          label={t("components.customize-page.data-consent-title")}
          {...form.getInputProps("dataConsentText")}
        />
        {/*        <TextInput
          withAsterisk
          label={t("components.customize-page.domain")}
          {...form.getInputProps("domain")}
        />*/}
        <Group position="right" mb={30} mt="md">
          <Button type="submit">{t("components.submit")}</Button>
          <Button
            variant="filled"
            color="red"
            onClick={() => {
              navigate("/admin", { state: "customize" });
            }}
          >
            {t("pages.admin.cancel")}
          </Button>
        </Group>
      </form>
    </Box>
  );
}
