import useCookie from "./useCookie";

const useSelectedLanguage = (defaultLanguage?: string) => {
  return useCookie({
    key: "language",
    defaultValue: defaultLanguage ?? "it",
  });
};

export default useSelectedLanguage;
