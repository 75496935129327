import { useParams } from "react-router-dom";
import { useGetApiPBITokens } from "../../api/endpoints/index";
import PbiReportComponent from "./PbiReportComponent";
import { useTranslation } from "react-i18next";

export function PbiReport() {
  let params = useParams();
  const { t } = useTranslation();
  const {
    data: pbi,
    error,
    isLoading,
  } = useGetApiPBITokens({
    workspaceId: params.wid ?? "",
    reportId: params.rid ?? "",
  });

  if (isLoading) return <div>{t("pages.admin.pbi.loading")}</div>;
  if (error) return <div>{t("pages.admin.pbi.error")}</div>;
  return <PbiReportComponent pbi={pbi!}></PbiReportComponent>;
}
