import {
  ActionIcon,
  Button,
  Card,
  Checkbox,
  Divider,
  Flex,
  Group,
  Input,
  Modal,
  Space,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import { postApiIndicatorGetIndicatorType } from "../../api/endpoints";
import { useEffect, useState } from "react";
import { IndicatorTypeDTO } from "../../api/model";
import useMiniDyiStore from "./miniDYIStore";
import SelectStakeholder from "./SelectStakeholder";
import { openModal } from "@mantine/modals";
import { IconInfoCircle, IconTrash } from "@tabler/icons";
import { useTranslation } from "react-i18next";
import { useDisclosure } from "@mantine/hooks";

const InfoTooltip = ({
  text,
  marginLeft,
}: {
  text: string;
  marginLeft?: number | string;
}) => {
  return (
    <Tooltip multiline width={200} label={text}>
      <div>
        <IconInfoCircle
          size={16}
          style={{
            display: "block",
            cursor: "pointer",
            marginLeft: marginLeft,
          }}
        ></IconInfoCircle>
      </div>
    </Tooltip>
  );
};

const SelectIndicator = () => {
  const { t } = useTranslation();
  const selectedOutcomes = useMiniDyiStore((state) => state.selectedOutcomes);
  const setOutcomeField = useMiniDyiStore((state) => state.setOutcomeField);
  const setIndicatorField = useMiniDyiStore((state) => state.setIndicatorField);
  const toggleIndicator = useMiniDyiStore((state) => state.toggleIndicator);
  const toggleOutcome = useMiniDyiStore((state) => state.toggleOutcome);

  const [proxyHelpModalOpened, { close: closeProxyHelpModal }] =
    useDisclosure(false);
  const [proxyValueHelpModalOpened, { close: closeProxyValueHelpModal }] =
    useDisclosure(false);
  const [
    KPIHelpModalOpened,
    { close: closeKPIHelpModal, open: openKPIHelpModal },
  ] = useDisclosure(false);
  const [indicatorTypeList, setIndicatorTypeList] =
    useState<IndicatorTypeDTO[][]>();
  useEffect(() => {
    const getIndicatorTypes = async () =>
      await postApiIndicatorGetIndicatorType(
        selectedOutcomes.filter((so) => !!so.sdg).map((out) => out?.outcomeId!)
      );

    getIndicatorTypes().then((indicatorTypes) => {
      console.log(indicatorTypes);
      setIndicatorTypeList(indicatorTypes);
    });
  }, [postApiIndicatorGetIndicatorType, selectedOutcomes]);

  return (
    <div>
      <Modal
        opened={proxyHelpModalOpened}
        onClose={closeProxyHelpModal}
        size="lg"
        title={t(
          "components.mini-dyi.indicator-financial-value-equivalent-change"
        )}
      >
        <Text>
          {t("components.mini-dyi.indicator-context-SROI-evaluation")}
        </Text>
        <br></br>
        <Text>{t("components.mini-dyi.indicator-nonprofit-organization")}</Text>
        <br></br>
        <Text>
          {t("components.mini-dyi.indicator-financial-value-outcome")}
        </Text>
      </Modal>
      <Modal
        opened={proxyValueHelpModalOpened}
        onClose={closeProxyValueHelpModal}
        size="lg"
        title={t("components.mini-dyi.indicator-delegation-converted-euros")}
      >
        <Text>
          {t("components.mini-dyi.indicator-impact-measurement-precise")}
        </Text>
        <br></br>
        <Text>{t("components.mini-dyi.indicator-example-social-cost")}</Text>
      </Modal>
      <Modal
        opened={KPIHelpModalOpened}
        onClose={closeKPIHelpModal}
        size="lg"
        title={t("components.mini-dyi.indicator-stakeholders-perceive-change")}
      >
        <Text>
          {t("components.mini-dyi.indicator-impact-calculated-annually")}
        </Text>
        <br></br>
        <Text>
          {t("components.mini-dyi.indicator-example-star-impact-investment")}
        </Text>
      </Modal>
      <Flex>
        <Title order={2}>{t("components.mini-dyi.indicators")}</Title>
        <Button
          variant="white"
          onClick={() =>
            openModal({
              children: (
                <>
                  <Text mb={10}>
                    {t("components.mini-dyi.category-stakeholders-target")}
                  </Text>
                  <Text mb={10}>
                    {t("components.mini-dyi.concept-financial-proxy")}
                  </Text>
                  <Text mb={10}>
                    {t("components.mini-dyi.standard-financial-proxy")}
                  </Text>
                  <Text mb={10}>{t("components.mini-dyi.impact-kpi")}</Text>
                  <Text mb={10}>{t("components.mini-dyi.impact-chain")}</Text>
                </>
              ),
            })
          }
        >
          <IconInfoCircle size={28} />
        </Button>
      </Flex>
      <Space h="md" />
      <Text size="sm">{t("components.mini-dyi.indicators-description")}</Text>

      <Space h="xl" />

      {selectedOutcomes
        .filter((so) => !!so.sdg)
        .map((out) => {
          return (
            <Card key={out.outcomeId} withBorder my={10} radius="md">
              <Flex align={"center"} justify={"space-between"} mb={15}>
                <Title order={3}>{out.outcomeName}</Title>
                <ActionIcon color="red" onClick={() => toggleOutcome(out)}>
                  <IconTrash size={16} />
                </ActionIcon>
              </Flex>
              <Group grow>
                <SelectStakeholder
                  selectedStakeholder={
                    selectedOutcomes.find((so) => so.outcomeId == out.outcomeId)
                      ?.stakeholderId!
                  }
                  setSelectedStakeholder={(stakeholder) => {
                    setOutcomeField(out, "stakeholderId", stakeholder);
                  }}
                ></SelectStakeholder>
                <Flex>
                  <InfoTooltip
                    text={t(
                      "components.mini-dyi.info-tooltip-pick-stakeholders"
                    )}
                  />
                </Flex>
              </Group>
              <Divider my={15} />
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: 20,
                }}
              >
                {indicatorTypeList &&
                  indicatorTypeList?.map((group) => {
                    return group
                      .filter((it) => it.outcomeTypeId == out.outcomeId)
                      .map((indicatortype) => {
                        return (
                          <Card
                            key={indicatortype.id}
                            withBorder
                            style={{
                              alignSelf: "stretch",
                              justifySelf: "stretch",
                            }}
                          >
                            <Flex
                              align={"center"}
                              onClick={() =>
                                toggleIndicator(out, {
                                  indicatorId: indicatortype.id,
                                  indicatorName: indicatortype.name,
                                  indicatorTargetValue: 0,
                                  proxyId: indicatortype.proxyTypeID,
                                  proxyName: indicatortype.proxyTypeName,
                                  proxyValue:
                                    indicatortype.proxyTypeDefaultValue,
                                })
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <Checkbox
                                m={10}
                                mr={20}
                                checked={
                                  !!out?.indicators?.find(
                                    (i) => i.indicatorId == indicatortype.id
                                  )
                                }
                              ></Checkbox>
                              <Text
                                size="md"
                                weight={500}
                                align="left"
                                style={{ maxWidth: "50ch" }}
                              >
                                {" "}
                                {indicatortype.name}
                              </Text>
                              <Flex style={{ marginLeft: 10 }}>
                                <InfoTooltip
                                  text={t(
                                    "components.mini-dyi.info-tooltip-indicator-flag"
                                  )}
                                />
                              </Flex>
                            </Flex>
                            <Divider my={20}></Divider>
                            <Input.Wrapper label={indicatortype.description}>
                              <Flex align={"center"}>
                                <Input
                                  style={{ width: "100%", marginRight:10 }}
                                  required={
                                    !!out?.indicators?.find(
                                      (i) => i.indicatorId == indicatortype.id
                                    )
                                  }
                                  error={
                                    !!out?.indicators?.find(
                                      (i) => i.indicatorId == indicatortype.id
                                    ) &&
                                    !out?.indicators?.find(
                                      (i) => i.indicatorId == indicatortype.id
                                    )?.indicatorTargetValue!
                                  }
                                  onChange={(event: any) =>
                                    setIndicatorField(
                                      out,
                                      indicatortype,
                                      "indicatorTargetValue",
                                      event.target.value
                                    )
                                  }
                                  value={Number(
                                    out?.indicators?.find(
                                      (i) => i.indicatorId == indicatortype.id
                                    )?.indicatorTargetValue!
                                  ).toString()}
                                  type={"number"}
                                  min={0}
                                />
                                  <InfoTooltip
                                      text={/*t(
                                          "components.mini-dyi.info-tooltip-indicator-flag"
                                      )*/
                                    "indica il tuo obiettivo di impatto"
                                  }
                                  />
                                  
                                
                              </Flex>
                            </Input.Wrapper>
                          </Card>
                        );
                      });
                  })}
              </div>
            </Card>
          );
        })}
    </div>
  );
};

export default SelectIndicator;
