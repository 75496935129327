import {
  Card,
  Divider,
  TextInput,
  Text,
  Flex,
  Input,
  NumberInput,
  Space,
  ActionIcon,
  Box,
} from "@mantine/core";
import SelectStakeholder from "./SelectStakeholder";
import { IconTrash } from "@tabler/icons";
import { useCallback } from "react";
import useMiniDyiStore, {
  compareIndicators,
  compareOutcomes,
  MiniDyiCustomIndicator,
  MiniDyiCustomOutcome,
} from "./miniDYIStore";
import { useTranslation } from "react-i18next";

function AddNewOutcome({ outcome }: { outcome: MiniDyiCustomOutcome }) {
  const selectedOutcomes = useMiniDyiStore((state) => state.selectedOutcomes);
  const toggleOutcome = useMiniDyiStore((state) => state.toggleOutcome);
  const setOutcomeField = useMiniDyiStore((state) => state.setOutcomeField);
  const setIndicatorField = useMiniDyiStore((state) => state.setIndicatorField);
  const getOutcome = useCallback(
    () => selectedOutcomes.find((out) => compareOutcomes(out, outcome)),
    [selectedOutcomes]
  );
  const getIndicator = useCallback(
    (indicator: MiniDyiCustomIndicator) =>
      selectedOutcomes
        .find((out) => compareOutcomes(out, outcome))
        ?.indicators?.find((i) => compareIndicators(i, indicator)),
    [selectedOutcomes, outcome]
  );

  const { t } = useTranslation();

  const indicators = outcome.indicators?.map((indicator) => (
    <Card
      key={indicator as string}
      withBorder
      style={{ alignSelf: "stretch", justifySelf: "stretch" }}
    >
      <Input.Wrapper label={t("components.mini-dyi.indicator-name")}>
        <Input
          w={"100%"}
          type={"text"}
          min={0}
          value={getIndicator(indicator)?.indicatorName!}
          onChange={(e: any) =>
            setIndicatorField(
              outcome,
              indicator,
              "indicatorName",
              e.target.value
            )
          }
        />
      </Input.Wrapper>
      <Divider my={20}></Divider>
      <Input.Wrapper label={t("components.mini-dyi.impact-kpi-label")}>
        <NumberInput
          type={"number"}
          min={0}
          value={getIndicator(indicator)?.indicatorTargetValue!}
          onChange={(value) =>
            value != "" &&
            setIndicatorField(outcome, indicator, "indicatorTargetValue", value)
          }
        />
      </Input.Wrapper>

      <Text mt={20} size="xs" align="left" color={"#aaa"}>
        {t("components.mini-dyi.financial-proxy")}
      </Text>
      <Divider mb={5}></Divider>
      <Input.Wrapper label={t("components.mini-dyi.proxy-name")}>
        <Input
          type={"text"}
          min={0}
          value={getIndicator(indicator)?.proxyName}
          onChange={(e: any) =>
            setIndicatorField(outcome, indicator, "proxyName", e.target.value)
          }
        />
      </Input.Wrapper>

      <NumberInput
        mt={5}
        label={t("components.mini-dyi.value")}
        value={getIndicator(indicator)?.proxyValue!}
        onChange={(value) =>
          value != "" &&
          setIndicatorField(outcome, indicator, "proxyValue", value)
        }
      />
    </Card>
  ));

  return (
    <Card withBorder ml={40} mr={40} my={20} p="lg" radius="md">
      <Flex align={"start"} justify={"space-between"} mb={15}>
        <Box w={"100%"}>
          <TextInput
            withAsterisk
            w={"100%"}
            label={t("components.mini-dyi.outcome-name")}
            value={getOutcome()?.outcomeName}
            onChange={(e) =>
              setOutcomeField(outcome, "outcomeName", e.target.value)
            }
          />
          <SelectStakeholder
            w={"100%"}
            mt={20}
            selectedStakeholder={getOutcome()?.stakeholderId!}
            setSelectedStakeholder={(val) =>
              setOutcomeField(outcome, "stakeholderId", val)
            }
          />
        </Box>
        <ActionIcon color="red" onClick={() => toggleOutcome(outcome)}>
          <IconTrash size={16} />
        </ActionIcon>
      </Flex>

      <Space h="xl" />

      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 30 }}>
        {indicators}
      </div>
    </Card>
  );
}

export default AddNewOutcome;
