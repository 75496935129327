import { Container } from "reactstrap";
import { Outlet, useNavigate } from "react-router-dom";
import { Flex } from "@mantine/core";
import styled from "@emotion/styled";
import TopBar from "../../components/TopBar";
import { useAuth } from "../../auth/AuthContext";

const StyledContainer = styled(Container)`
  padding-top: 6rem;
  overflow-y: scroll;
  height: 100vh;
`;
const StyledFlex = styled(Flex)`
  background-color: #fafafa;
`;

function AdminLayout() {
  const { isAuthenticated, isAdmin } = useAuth();
  const navigate = useNavigate();
  if (!isAuthenticated || !isAdmin) {
    navigate("/", { replace: true });
  }

  return (
    <StyledFlex>
      <TopBar />
      <StyledContainer>
        <Outlet />
      </StyledContainer>
    </StyledFlex>
  );
}

export default AdminLayout;
