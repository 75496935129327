import { MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { NotificationsProvider } from "@mantine/notifications";
import { Route, Routes } from "react-router-dom";
import { preload } from "swr";
import {
  getApiCallGetCallList,
  getApiCallGetSubmissionByID,
  getApiOrganizationGetOrganizations,
  getGetApiCallGetCallListKey,
  getGetApiCallGetSubmissionByIDKey,
  getGetApiOrganizationGetOrganizationsKey,
} from "./api/endpoints";
import { AuthProvider } from "./auth/AuthContext";
import Layout from "./components/Layout";
import LoginCallback from "./components/LoginCallback";
import LogoutCallback from "./components/LogoutCallback";
import "./custom.css";
import AdminLayout from "./pages/admin/AdminLayout";
import CreateCall from "./pages/admin/CreateCall";
import CreateCallType from "./pages/admin/CreateCallType";
import CreateSurvey from "./pages/admin/createSurvey";
import CreateSurveyType from "./pages/admin/createSurveyType";
import Dashboard from "./pages/admin/Dashboard";
import FileUpload from "./pages/admin/FileUpload";
import Call from "./pages/calls/call";
import CallList from "./pages/calls/callList";
import Steps from "./pages/calls/steps";
import OrganizationEdit from "./pages/organizations/organizationEdit";
import OrganizationsList from "./pages/organizations/organizationsList";
import { PbiReport } from "./pages/powerBi/PbiReport";
import PbiReportList from "./pages/powerBi/PbiReportList";
import SurveyLayout from "./pages/survey/surveyLayout";

import { useEffect } from "react";
import { I18nextProvider } from "react-i18next";
import RegisterOrganization from "./components/forms/RegisterOrganization";
import EditCall from "./pages/admin/EditCall";
import WelcomePage from "./pages/WelcomePage";
import i18n from "./translations";
import useSelectedLanguage from "./hooks/useSelectedLanguage";
import { ConfigProvider } from "./hooks/useConfig";
import CreateFramework from "./components/forms/CreateFramework";
import CustomizeInstallation from "./components/forms/CustomizeInstallation";
import StepsListReport from "./pages/admin/StepsListReport";
import ReportByStep from "./pages/admin/sections/ReportByStep";
import FrameworkView from "./pages/admin/FrameworkView";
import AttachmentsList from "./pages/admin/AttachmentsList";

export default function App() {
  const [selectedLanguage, setSelectedLanguage] = useSelectedLanguage();

  useEffect(() => {
    setSelectedLanguage(selectedLanguage, 30);
  }, [selectedLanguage, setSelectedLanguage]);
  i18n.changeLanguage(selectedLanguage || "en");

  return (
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      theme={{
        fontFamily: "Manrope, sans-serif",
        fontFamilyMonospace: "Monaco, Courier, monospace",
        headings: { fontFamily: "Manrope, sans-serif" },
      }}
    >
      <ConfigProvider>
        <I18nextProvider i18n={i18n}>
          <ModalsProvider>
            <NotificationsProvider>
              <AuthProvider>
                <Routes>
                  <Route path="/admin" element={<AdminLayout />}>
                    <Route index element={<Dashboard />} />
                    <Route path="steps" element={<Steps />} />
                    <Route
                      path="attachments/:subId/"
                      element={<AttachmentsList />}
                    />
                    <Route
                      path="report-list/:callId/:callTypeId"
                      element={<StepsListReport />}
                    />
                    <Route
                      path="report/:callId/:stepId"
                      element={<ReportByStep />}
                    />
                    <Route path="survey" element={<SurveyLayout />}>
                      <Route path="creator" element={<CreateSurvey />} />
                      {/* <Route path=":submissionId" element={<Survey />} /> */}
                      <Route path="createType" element={<CreateSurveyType />} />
                    </Route>
                    <Route
                      path="customize"
                      element={<CustomizeInstallation />}
                    />
                    <Route path="calls">
                      <Route path=":callId" element={<CreateCall />} />
                      <Route path="create/type" element={<CreateCallType />} />
                      <Route path="edit/call/:callId" element={<EditCall />} />
                      {/* <Route path=":submissionId" element={<Survey />} /> */}
                      <Route
                        path="create/survey"
                        element={<CreateSurveyType />}
                      />
                    </Route>
                    <Route
                      path="impact-framework"
                      element={<CreateFramework />}
                    />
                    <Route
                      path="impact-framework/:id"
                      element={<FrameworkView />}
                    />
                    <Route path="pbi-report-list" element={<PbiReportList />} />
                    <Route
                      path="powerbi-report/:wid/:rid"
                      element={<PbiReport />}
                    />
                    <Route path="file-upload" element={<FileUpload />} />
                  </Route>

                  <Route path="/" element={<Layout />}>
                    <Route index element={<WelcomePage />} />
                    <Route
                      path="calls"
                      element={<CallList />}
                      loader={() => {
                        preload(
                          getGetApiCallGetCallListKey(),
                          getApiCallGetCallList
                        );
                        preload(
                          getGetApiOrganizationGetOrganizationsKey(),
                          getApiOrganizationGetOrganizations
                        );
                      }}
                    />
                    <Route path="login-callback" element={<LoginCallback />} />
                    <Route
                      path="register-org"
                      element={<RegisterOrganization />}
                    />
                    <Route path="org/list" element={<OrganizationsList />} />
                    <Route
                      path="org/edit/:organizationId"
                      element={<OrganizationEdit />}
                    />
                    <Route
                      path="logout-callback"
                      element={<LogoutCallback />}
                    />
                    <Route
                      loader={({ params }) =>
                        preload(
                          getGetApiCallGetSubmissionByIDKey(params),
                          getApiCallGetSubmissionByID
                        )
                      }
                      path="/call/:submissionId/*"
                      element={<Call />}
                    />
                  </Route>
                </Routes>
              </AuthProvider>
            </NotificationsProvider>
          </ModalsProvider>
        </I18nextProvider>
      </ConfigProvider>
    </MantineProvider>
  );
}
