import { Table } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useGetApiFileUploadGetAttachments } from "../../api/endpoints";

function FilesList() {
  const { data: files } = useGetApiFileUploadGetAttachments();
  const { t } = useTranslation();

  const rows = files?.map((element: any) => (
    <tr key={element.nomeFile}>
      <td>{element.descrizione}</td>
      <td>{element.nomeAllegato}</td>
      <td>{element.createdAt.toString()}</td>
    </tr>
  ));
  return (
    <Table>
      <thead>
        <tr>
          <th>{t("pages.admin.file-name")}</th>
          <th>{t("pages.admin.description")}</th>
          <th>{t("pages.admin.attachment-name")}</th>
          <th>{t("pages.admin.created-date")}</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </Table>
  );
}

export default FilesList;
