import { Box, Button, Group, Loader, Paper, Text } from "@mantine/core";
import {
  Dropzone,
  FileWithPath,
  IMAGE_MIME_TYPE,
  MS_EXCEL_MIME_TYPE,
  MS_WORD_MIME_TYPE,
  PDF_MIME_TYPE
} from "@mantine/dropzone";
import { IconAsteriskSimple, IconX } from "@tabler/icons";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { postApiFileUploadUploadFile, useGetApiFileUploadGetAttachmentTypes } from "../api/endpoints";
import { ExtraInfoAllegatoResult, PostApiFileUploadUploadFileBody } from "../api/model";
import Loading from "./Loading";
import { FileContent } from "./UploadList";




export default function UploadFile({ content, data, setData }: { content: FileContent, data: ExtraInfoAllegatoResult | undefined, setData: (value: ExtraInfoAllegatoResult) => void }) {
  const { t } = useTranslation();
  const [uploaded, setUploaded] = useState(false);
  const [loader, setLoader] = useState(false);
  const [errorDimension, setErrorDimension] = useState<boolean>(false);
  const [fileChoice, setFileChoice] = useState<ExtraInfoAllegatoResult>();

  const pdfFormat = PDF_MIME_TYPE;
  const ExcelFormat = MS_EXCEL_MIME_TYPE;
  const WordFormat = MS_WORD_MIME_TYPE;
  const ImageFormat = IMAGE_MIME_TYPE;

  const {
    data: dataAttachments,
    isLoading: isLoadingAttachments,
  } = useGetApiFileUploadGetAttachmentTypes();

  const removeFileUpload = () => {
    setLoader(false);
    setUploaded(false);
    setFileChoice({});
    setErrorDimension(false);
  };

  useEffect(() => {
    if (typeof data != "undefined") {
      setUploaded(true);
      setFileChoice(data)
    }
  }, [data])

  function uploadFile(file: FileWithPath) {
    setLoader(true);
    const fileUploadReq: PostApiFileUploadUploadFileBody = {
      Nome: file.name,
      Descrizione: content.file_description,
      TipoFileId: dataAttachments?.filter((e) => e.attachedType.toUpperCase() === content.file_type.toUpperCase()).map((e) => e.id)[0] ?? "",
      File: file,
    };
    if (file.size > content.dimensions) {
      setErrorDimension(true);
       setLoader(false);
    }
    console.log(content.file_type)
    console.log(dataAttachments)
    console.log(fileUploadReq)
    postApiFileUploadUploadFile(fileUploadReq)
      .then((ret) => {
        setData(ret);
        setFileChoice(ret)
        setUploaded(true);
        setLoader(false);
      })
      .catch((e) => {
        console.log("🚀 ~ file: SurveyAllegati.tsx:114 ~ uploadFile ~ e:", e);
        setLoader(false);
      });
  }

  function downloadFile(url: string, filename: string) {
    fetch(url).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = filename;
        alink.click();
      });
    });
  }

  if (isLoadingAttachments) {
    return <Loading />;
  }
  return (
    <>
      <Text size={20}>
        {content.file_name}
        {
          content.mandatory
            ? <IconAsteriskSimple size={10} color="red" style={{ marginTop: -15 }} />
            : ""
        }
      </Text>
      <Text size={14} color="gray">
        {content.file_description}
      </Text>
      <Group mx={20} ml={loader ? 40 : -11}>
        {loader ? (
          <Loader />
        ) : (
          <>

            <Paper>
              {uploaded ? (
                <Group >
                  <Text>
                    {fileChoice?.fileName}
                  </Text>
                  <IconX
                    style={{ color: "grey", cursor: "pointer" }}
                    onClick={removeFileUpload}
                  />
                  <Button variant="subtle" fw={600} onClick={() => {
                    downloadFile(
                      `/api/FileUpload/GetFile?downloadId=${fileChoice?.fileDownloadId}`,
                      fileChoice?.fileName!
                    )
                  }}>
                    {t("components.upload-file.download")}
                  </Button>

                </Group>
              ) :
                <Dropzone
                  onDrop={(files) => {
                    uploadFile(files[0])
                  }}
                  accept={
                    content.file_type === "pdf"
                      ? pdfFormat
                      : content.file_type === "excel"
                        ? ExcelFormat
                        : content.file_type === "word"
                          ? WordFormat
                          : []
                  }
                  sx={{ border: 0, }}
                  // maxSize={content.dimensions}
                >

                    <Text>
                {t("components.upload-file.drop-file")}
                      
                    </Text>
                  <Text size={10} color="gray">
                {t("components.upload-file.max-dimension")}
                     {content.dimensions} bytes
                  </Text>

                </Dropzone>}
              {errorDimension ? (<Text color="red">
                {t("components.upload-file.dimensions-document-number-unit")}
              </Text>) : ""}
            </Paper>

          </>
        )}
      </Group>


    </>
  );
}
