import {
  Box,
  Button,
  Checkbox,
  Group,
  Stack,
  TextInput,
  Tooltip,
  Divider,
  Title,
  Text,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { closeAllModals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import { IconInfoCircle } from "@tabler/icons";
import {
  getGetApiOrganizationGetOrganizationsKey,
  postApiOrganizationCreateOrganization,
  useGetApiUser,
} from "../../api/endpoints";
import { CreateOrgDto } from "../../api/model";
import { mutate } from "swr";
import { useTranslation } from "react-i18next";
import { useConfig } from "../../hooks/useConfig";

function RegisterOrganization() {
  const { t } = useTranslation();
  const { data: user } = useGetApiUser();
  const config = useConfig();
  const form = useForm<CreateOrgDto>({
    initialValues: {
      contact_name: user?.firstName,
      contact_surname: user?.lastName,
      contact_email: user?.email,
      contact_role: "",
      personalData: false,
      selfCertification: false,
      termsOfService: false,
      nordicCountry: false,
      preFunding: false,
      chnageInLife: false,
    },
  });

  return (
    <Box mx="auto">
      <form
        onSubmit={form.onSubmit((values) =>
          postApiOrganizationCreateOrganization(values).then((res) => {
            if (res) {
              mutate(getGetApiOrganizationGetOrganizationsKey());
              showNotification({
                title: t("components.forms.organization-registered"),
                message: t(
                  "components.forms.organization-registered-successfully"
                ),
              });
              closeAllModals();
            }
          })
        )}
      >
        <Stack mt="xl">
          <h5>{t("components.forms.general-info")}</h5>
          <TextInput
            withAsterisk
            label={t("components.forms.company-name")}
            {...form.getInputProps("name")}
          />
          <TextInput
            withAsterisk
            label={t("components.forms.registration-number")}
            {...form.getInputProps("reg_number")}
            rightSection={
              <Tooltip
                multiline
                width={220}
                label={t("components.forms.company-tax")}
              >
                <div>
                  <IconInfoCircle size={14}></IconInfoCircle>
                </div>
              </Tooltip>
            }
          />
        </Stack>
        <Stack mt="xl">
          <h5>{t("components.forms.address")}</h5>
          <TextInput
            withAsterisk
            label={t("components.forms.address")}
            {...form.getInputProps("street_name")}
          />
          <TextInput
            withAsterisk
            label={t("components.forms.city-name")}
            {...form.getInputProps("city_name")}
          />
          <TextInput
            withAsterisk
            label={t("components.forms.country-name")}
            {...form.getInputProps("country")}
          />
        </Stack>
        <Stack mt="xl">
          <h5>{t("components.forms.contact-person")}</h5>
          <TextInput
            withAsterisk
            label={t("components.forms.contact-name")}
            {...form.getInputProps("contact_name")}
          />
          <TextInput
            withAsterisk
            label={t("components.forms.surname")}
            {...form.getInputProps("contact_surname")}
          />
          <TextInput
            withAsterisk
            label={t("components.forms.role-organization")}
            {...form.getInputProps("contact_role")}
          />
          <TextInput
            withAsterisk
            label={t("components.forms.email")}
            placeholder={t("components.forms.email-placeholder")}
            {...form.getInputProps("contact_email")}
          />
          <Divider my={20}></Divider>
          <Title order={4}>
            {t("components.forms.processing-personal-data")}
          </Title>
          <Text size={"sm"}>
            {config.dataConsentText
              ? config.dataConsentText
              : t("components.forms.processing-personal-data-documentation")}
          </Text>
          <Checkbox
            mt="md"
            size="xs"
            label={t("components.forms.agree")}
            {...form.getInputProps("personalData", { type: "checkbox" })}
          />
        </Stack>

        <Group position="right" mt="md">
          <Button
            type="submit"
            disabled={
              !form.values.personalData ||
              !form.values.name ||
              !form.values.reg_number ||
              !form.values.city_name ||
              !form.values.country ||
              !form.values.street_name ||
              !form.values.contact_email ||
              !form.values.contact_name ||
              !form.values.contact_role ||
              !form.values.contact_surname
            }
          >
            {t("components.forms.submit")}
          </Button>
        </Group>
      </form>
    </Box>
  );
}

export default RegisterOrganization;
