import { Center, Flex, Group, Input, Loader } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { IconX } from "@tabler/icons";
import { useState } from "react";
import * as XLSX from "xlsx";
import { camelCase } from "lodash";

function createObjectFromArrays<T>(
  keys: string[],
  values: T[]
): { [key: string]: T } {
  if (keys.length !== values.length) {
    throw new Error("Keys and values arrays must have the same length");
  }

  const result: { [key: string]: T } = {};

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const value = values[i];
    result[key] = value;
  }

  return result;
}

export default function ExcelReader({
  form,
}: {
  form: UseFormReturnType<any, (values: any) => any>;
}) {
  const [iconx, setIconx] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loadedData, setLoadedData] = useState({});
  

  const handleFileUpload = (event: any) => {
    const file = event.target.files[0];

    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt?.target?.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames;
      wsname.forEach((e) => {
        const ws = wb.Sheets[e];
        const data: any = XLSX.utils.sheet_to_json(ws, { header: 1 });

        if (Array.isArray(data) && data.length) {
          const keys = data[0]!.map((s: string) =>
            camelCase(s.replaceAll(" ", ""))
          );
          const map = data.map((r, i) => {
            if (i == 0 || !r.length) return null;
            return createObjectFromArrays(keys, r);
          }).filter(x=>x!=null);
          setLoadedData(Object.assign(loadedData,{[e]: map}))
        }
      });
      form.setFieldValue("excel_design_your_impact", loadedData);
      console.log("MAPPED: ", loadedData);
      setIconx(true);
    };

    reader.readAsBinaryString(file);
  };

  const removeFileUpload = () => {
    setLoader(true);
    form.reset()
    const timer = setTimeout(() => {
      setLoader(false);
      setIconx(false);
    }, 1000);
    return () => clearTimeout(timer);
  };

  if (loader) {
    return (
      <Center mt={20} mb={10}>
        <Loader />
      </Center>
    );
  }

  return (
    <Flex align={"center"} justify={"center"}>
      <Group position="center" mt={20} mb={10}>
        <Input
          styles={{
            input: { border: 0, minHeight: "2.5rem" },
          }}
          sx={{
            ".mantine-Input-input": {
              backgroundColor: "transparent",
            },
          }}
          type="file"
          onChange={handleFileUpload}
          accept=".xls,.xlsx"
        />
      </Group>
      {iconx && (
        <IconX
          style={{ color: "grey", cursor: "pointer" }}
          onClick={removeFileUpload}
        />
      )}
    </Flex>
  );
}
