import {Accordion, Box, Flex, Group, Text} from "@mantine/core";
import { IconChevronDown } from "@tabler/icons";
import { ImpactFrameworkDTO } from "../../api/model";
import { useTranslation } from "react-i18next";

const AccordionStakeholders = ({
  framework,
}: {
  framework: ImpactFrameworkDTO;
}) => {
  const { t } = useTranslation();
  const TextBold = ({ value }: { value: string | number | undefined }) => {
    return <span style={{ fontWeight: 600 }}>{value}</span>;
  };
  const TextLight = ({ value }: { value: string | number | undefined }) => {
    return <span style={{ fontWeight: "lighter" }}>{value}</span>;
  };

  return (
    <>
      {framework.stakeholders?.map((r, i) => {
        return ( <Group key={i}>
          <TextBold value={r.name ?? "-"} />
          <TextLight value={r.description ?? "-"} />
        </Group>)
      })}
      
    </>
  );
};

export default AccordionStakeholders;
