import quizAnswers from "../../assets/json/quiz-answers.json";
import "survey-core/modern.min.css";
import { StylesManager, Model } from "survey-core";
import { Survey as SurveyComponent } from "survey-react-ui";
import { useGetApiCallGetStepResult } from "../../api/endpoints";
import { useParams } from "react-router-dom";
import { Step } from "../../api/model";
import Loading from "../../components/Loading";
import { ForwardedRef, forwardRef, useImperativeHandle } from "react";

StylesManager.applyTheme("modern");

type Sender = {
  data: any;
};
export type quizResultsType = {
  content: any;
  quizResults: {
    recap: Record<string, boolean>;
    correctAnswersCount: number;
  };
};
const Survey = forwardRef(
  (
    {
      model,
      isQuiz = false,
      setData,
      step,
      readOnly,
    }: {
      isQuiz?: boolean;
      model: string;
      setData: (data: string, score?: number) => void;
      step: Step;
      readOnly?: boolean;
    },
    ref: ForwardedRef<any>
  ) => {
    const survey = new Model(model);
    useImperativeHandle(ref, () => ({
      validate() {
        console.log("validate called");
        return survey.completeLastPage();
      },
    }));
    const { submissionId } = useParams();
    const {
      data: currentStepResult,
      isLoading: srLoading,
      error: srErr,
    } = useGetApiCallGetStepResult(
      { submissionId: submissionId!, StepId: step.id },
      {
        swr: {
          shouldRetryOnError: false,
          revalidateIfStale: true,
          revalidateOnFocus: false,
          revalidateOnReconnect: false,
        },
      }
    );
    const saveResults = (sender: Sender) => {
      if (isQuiz) {
        const qa = quizAnswers as Record<string, string[]>;
        const quizResults = Object.keys(sender.data).reduce<{
          recap: any;
          correctAnswersCount: number;
        }>(
          (acc, curr) => {
            if (
              sender.data[curr] &&
              qa[curr] &&
              Array.isArray(sender.data[curr]) &&
              Array.isArray(qa[curr]) &&
              sender.data[curr].length &&
              qa[curr].length
            ) {
              if (sender.data[curr][0] == qa[curr][0]) {
                acc.recap[curr] = true;
                acc.correctAnswersCount = acc.correctAnswersCount + 1;
              } else {
                acc.recap[curr] = false;
              }
            }
            return acc;
          },
          { recap: {}, correctAnswersCount: 0 }
        );
        const results: quizResultsType = { content: sender.data, quizResults };
        setData(
          JSON.stringify(results),
          quizResults.correctAnswersCount / Object.keys(sender.data).length
        );
      } else {
        const results = JSON.stringify(sender.data);
        setData(results);
      }
    };

    survey.focusFirstQuestionAutomatic = false;
    survey.mode = readOnly ? "display" : "edit";
    
    survey.onComplete.add(saveResults);
    survey.onValueChanged.add(saveResults);
    if (srLoading) {
      return <Loading></Loading>;
    }

    if (!srLoading && !srErr && currentStepResult?.stepId === step.id) {
      const content = JSON.parse(currentStepResult?.content!);
      const surveyData = isQuiz ? content.content : content;
    
      survey.data = surveyData;
      survey.mode = readOnly ? "display" : "edit";
    
    }

    return <SurveyComponent model={survey} />;
  }
);

export default Survey;
