import React, { useState, useEffect } from "react";
import "survey-analytics/survey.analytics.min.css";
import { Model, SurveyModel } from "survey-core";
import { VisualizationPanel } from "survey-analytics";
import { Step, StepResultResponse } from "../../api/model";
import {Center} from "@mantine/core";
import {useTranslation} from "react-i18next";

const vizPanelOptions = {
  allowHideQuestions: false,
};

export default function SurveyJsDashboard({
  step,
  results,
}: {
  step: Step;
  results: StepResultResponse[];
}) {
  const {t} = useTranslation()
  const [survey, setSurvey] = useState<SurveyModel>();
  const [vizPanel, setVizPanel] = useState<VisualizationPanel>();

  if (!survey) {
    try {
      const survey = new Model(JSON.parse(step.content!));
      setSurvey(survey);
    }catch (e) {
      console.error(e)
    }
  }

  if (!vizPanel && !!survey && !!results) {
    const vizPanel = new VisualizationPanel(
      survey.getAllQuestions(),
      results.map((r) => JSON.parse(r.content!)),
      vizPanelOptions
    );
    vizPanel.showToolbar = false;
    setVizPanel(vizPanel);
  }

  useEffect(() => {
    vizPanel?.render("surveyVizPanel");
    return () => {
      let vizDocument = document.getElementById("surveyVizPanel")?.innerHTML;
      vizDocument = "";
    };
  });
  if(!step.content) return (<div>
    <Center>
      {t("pages.admin.no-survey-results","no submission data")}
    </Center>
  </div>)
  return <div id="surveyVizPanel" />;
}
