import { Center, Loader } from "@mantine/core";

type Props = {};

function Loading(p: Props = {}) {
  return (
    <Center w={"100%"} h={"80%"}>
      <Loader mx="auto" mt="3rem"></Loader>
    </Center>
  );
}

export default Loading;
