import {
  Anchor,
  Avatar,
  Button,
  Flex,
  Group,
  Header,
  Menu,
  Text,
} from "@mantine/core";
import { ReactComponent as Logo } from "../assets/svg/OI_logo.svg";
import {
  IconAffiliate,
  IconLogout,
  IconPhoto,
  IconSettings,
  IconUser,
  IconUserPlus,
} from "@tabler/icons";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useGetApiUser } from "../api/endpoints";
import { useAuth } from "../auth/AuthContext";
import { SelectLanguage } from "./SelectedLanguage";
import { useConfig } from "../hooks/useConfig";

function TopBar() {
  const { isAuthenticated, isAdmin } = useAuth();
  const { t } = useTranslation();
  const { data: user } = useGetApiUser();
  const config = useConfig();

  return (
    <Header height="5rem" p="xs" fixed top={0} dir="row" zIndex={100} px={20}>
      <Flex mt={10} justify="space-between" align={"center"}>
        <Group ml={10} align={"center"}>
          <Link to="/">
            {config.logoBase64 ? (
              <img
                src={config.logoBase64}
                alt="logo"
                style={{ height: "3rem", width: "auto" }}
              />
            ) : (
              <Logo width={120} />
            )}
          </Link>
        </Group>

        <Group grow mx="-xs" px="xs">
          {/* */}
        </Group>
        <Group></Group>
        <Group>
          <Group>
            {user && isAuthenticated && (
              <Menu trigger="hover" openDelay={100} closeDelay={400}>
                {config.languageSelection && <SelectLanguage />}
                <Menu.Target>
                  <Group>
                    <Text>
                      {user?.firstName} {user?.lastName}
                    </Text>
                    <Avatar color="cyan" radius="xl">
                      {user.firstName &&
                        user.lastName &&
                        (
                          user?.firstName![0] + user?.lastName![0]
                        ).toUpperCase()}
                    </Avatar>
                  </Group>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item
                    component={Link}
                    to="/org/list"
                    icon={<IconAffiliate size={14} />}
                  >
                    {t("components.your-organizations")}
                  </Menu.Item>
                  <Menu.Item
                    component={Link}
                    to="/calls"
                    icon={<IconPhoto size={14} />}
                  >
                    {t("components.back-calls")}
                  </Menu.Item>
                  {isAdmin && (
                    <Menu.Item
                      component={Link}
                      to="/admin"
                      icon={<IconSettings size={14} />}
                    >
                      {t("components.admin-panel")}
                    </Menu.Item>
                  )}
                  <Menu.Item
                    component="a"
                    href={`https://${process.env.REACT_APP_FUSIONAUTH_HOST}/oauth2/logout?client_id=${process.env.REACT_APP_FUSIONAUTH_CLIENT_ID}`}
                    color={"red"}
                    icon={<IconLogout size={14} />}
                  >
                    {t("components.logout")}
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            )}
          </Group>
          <Group>
            {user == null && (
              <>
                <Anchor
                  href={`https://${process.env.REACT_APP_FUSIONAUTH_HOST}/oauth2/authorize?client_id=${process.env.REACT_APP_FUSIONAUTH_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_FUSIONAUTH_REDIRECT_URI}`}
                >
                  <Button
                    variant="light"
                    radius="xl"
                    leftIcon={<IconUser size={14} />}
                  >
                    {t("components.login")}
                  </Button>
                </Anchor>
                <Anchor
                  href={`https://${process.env.REACT_APP_FUSIONAUTH_HOST}/oauth2/register?client_id=${process.env.REACT_APP_FUSIONAUTH_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_FUSIONAUTH_REDIRECT_URI}`}
                >
                  <Button
                    variant="filled"
                    radius="xl"
                    leftIcon={<IconUserPlus size={14} />}
                  >
                    {t("components.register")}
                  </Button>
                </Anchor>
              </>
            )}
          </Group>
        </Group>
      </Flex>
    </Header>
  );
}

export default TopBar;
