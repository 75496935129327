import { Button, Group, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useTranslation } from "react-i18next";
import { postApiStepCreateStepType } from "../../api/endpoints";
import { CreateStepTypeDto } from "../../api/model/createStepTypeDto";

function CreateStepType() {
  const { t } = useTranslation();
  const form = useForm<CreateStepTypeDto>({
    initialValues: {
      name: "",
    },
  });

  return (
    <form
      onSubmit={form.onSubmit((values) => postApiStepCreateStepType(values))}
    >
      <TextInput
        withAsterisk
        label={t("components.forms.description")}
        placeholder={t("components.forms.step-type")}
        {...form.getInputProps("name")}
      />
      <Group position="right" mt="md">
        <Button type="submit">{t("components.forms.create-step-type")}</Button>
      </Group>
    </form>
  );
}

export default CreateStepType;
