import {Button, Center, Group, Loader, Table, Text} from "@mantine/core";
import {IconArrowLeft, IconX} from "@tabler/icons";
import {useNavigate, useParams} from "react-router-dom";
import {
    useGetApiCallGetSubmissionByID,
    useGetApiCallGetSubmissionByIDAdmin,
    useGetApiStepGetSteps
} from "../../api/endpoints";
import { useTranslation } from "react-i18next";
import {ExtraInfoAllegatoResult, SubmissionResponse} from "../../api/model";

const AttachmentsTable = ({submission}:{submission:SubmissionResponse}) => {
  const { t } = useTranslation();
return (
    <>
{/*
        <pre>{JSON.stringify(submission,null,2)}</pre>
*/}
  <Table mt={20}>
    <thead>
      <tr>
        <th>{t("pages.admin.section.attachment.name")}</th>
        <th>{t("pages.admin.section.attachment.type")}</th>
        <th>{t("pages.admin.section.attachment.description")}</th>
        <th>{t("pages.admin.section.attachment.download")}</th>
      </tr>
    </thead>
    <tbody>
    {submission?.stepResults?.map((step) => {
            const fileChoices =JSON.parse(step.content!) as ExtraInfoAllegatoResult[]
            return (Array.isArray(fileChoices) && fileChoices.length) ? fileChoices.map((fileChoice:ExtraInfoAllegatoResult)=>(<tr>
                    <td>
                        {fileChoice?.fileName ?? "file non caricato"}
                    </td>
                    <td>
                        {fileChoice?.tipoAllegatoName}
                    </td>
                    <td>
                        {fileChoice?.descrizione}
                    </td>
                {fileChoice?.fileName && <Button variant="subtle" fw={600} onClick={() => {
                    downloadFile(
                        `/api/FileUpload/GetFile?downloadId=${fileChoice?.fileDownloadId}`,
                        fileChoice?.fileName!
                    )
                }}>
                    {t("components.upload-file.download")}
                </Button>}

                </tr>)) : <></>
        }
    )}
      
    </tbody>
  </Table>
    </>
);
};

const AttachmentsList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
    
  const params = useParams();
     const { data: submission, isLoading:submissionLoading } = useGetApiCallGetSubmissionByIDAdmin({submissionId: params.subId})

  //TODO: una volta agganciata la chiamata, prendere il "data" e fare il map nel tBody


 if (!submission) {
   return (
     <Center>
       <Loader />
     </Center>
   );
 }
  return (
    <>
      <Button
        variant="subtle"
        style={{ marginLeft: -14 }}
        leftIcon={<IconArrowLeft />}
        onClick={() => navigate("/admin", { state: "submissions" })}
      >
        {t("pages.admin.go-back")}
      </Button>
        
      <AttachmentsTable submission={submission}/>
    </>
  );
};

function downloadFile(url: string, filename: string) {
    fetch(url).then((response) => {
        response.blob().then((blob) => {
            const fileURL = window.URL.createObjectURL(blob);
            let alink = document.createElement("a");
            alink.href = fileURL;
            alink.download = filename;
            alink.click();
        });
    });
}
export default AttachmentsList;
